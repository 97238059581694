<template>
  <el-dialog
    v-bind="{title: submitForm.persId ? '编辑外部人员': '新增外部人员', width: '500px'}"
    v-dialogDrag
    @closed="handlerClose"
    :visible.sync="visible">
    <el-form ref="form" label-width="150px" :model="submitForm" :rules="rules">
      <FormModule title="基础信息">
        <el-form-item label="任职企业" prop="lastCompId">
          <el-select class="w-250" v-model="submitForm.lastCompId" clearable placeholder="请选择">
            <el-option v-for="item in complist" :key="item.compId" :value="item.compId" :label="item.compName"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="职位" :prop="postType==1?'ordiPosit':'seniPosit'" >
          <div class="flex w-250">
            <el-select v-model="postType" class="w-100" @change="submitForm.seniPosit=submitForm.ordiPosit=''">
              <el-option :value="1" label="普通岗位"></el-option>
              <el-option :value="2" label="重要岗位"></el-option>
            </el-select>
            <el-select v-if="postType==2" class="flex-1" v-model="submitForm.seniPosit" clearable placeholder="请选择">
              <el-option v-for="item in impPosts" :key="item.id" :value="item.id" :label="item.name" />
            </el-select>
            <el-input v-else class="flex-1" v-model.trim="submitForm.ordiPosit" placeholder="请输入"></el-input>
          </div>
        </el-form-item>
        <el-form-item label="职责日期时间" prop="dutyDatTim">
          <el-date-picker
            class="w-250"
            v-model="submitForm.dutyDatTim"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            placeholder="请选择职责日期时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="职责状态" prop="dutyStat">
          <el-select class="w-250" v-model="submitForm.dutyStat" clearable placeholder="请选择">
            <el-option v-for="item in dutyTypes" :key="item.id" :value="item.id" :label="item.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="人员类型" prop="persType">
          <el-select class="w-250" v-model="submitForm.persType" clearable placeholder="请选择">
            <el-option v-for="item in persTypes" :key="item.id" :value="item.id" :label="item.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="姓名" prop="persName">
          <el-input class="w-250" v-model.trim="submitForm.persName" clearable placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
          <el-input class="w-250" v-model.trim="submitForm.phone" clearable placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="性别" prop="gend">
          <el-radio-group v-model="submitForm.gend">
            <el-radio label="男"></el-radio>
            <el-radio label="女"></el-radio>
            <el-radio label="其他"></el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="身份证号" prop="cardID">
          <el-input class="w-250" v-model.trim="submitForm.cardID" clearable placeholder="请输入"></el-input>
        </el-form-item>

        <el-form-item label="出生日期" prop="birth">
          <el-date-picker
            class="w-250"
            v-model="submitForm.birth"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择出生日期">
          </el-date-picker>
        </el-form-item>

        <el-form-item label="文化程度" prop="educDegr">
          <el-select class="w-250" v-model="submitForm.educDegr" clearable placeholder="请选择">
            <el-option v-for="item in educs" :key="item.id" :value="item.id" :label="item.name"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="政治面貌" prop="politics">
          <el-select class="w-250" v-model="submitForm.politics" clearable placeholder="请选择">
            <el-option v-for="item in politicalStatus" :key="item.id" :value="item.id" :label="item.name"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="是否交社保" prop="sociSecu">
          <el-radio-group v-model="submitForm.sociSecu">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="详细地址" prop="famiAddr">
          <el-input type="textarea" class="w-250" v-model.trim="submitForm.famiAddr" clearable placeholder="请输入"></el-input>
        </el-form-item>
      </FormModule>

      <FormModule title="驾照信息" v-if="submitForm.persType == 4">
        <!-- 驾照信息 -->
        <el-form-item label="驾照起始有效日期" prop="starLiceDat">
          <el-date-picker
            class="w-250"
            v-model="submitForm.starLiceDat"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="驾照结束有效日期" prop="endLiceDat">
          <el-date-picker
            class="w-250"
            v-model="submitForm.endLiceDat"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="驾驶证号" prop="liceCode">
          <el-input class="w-250" v-model="submitForm.liceCode" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="驾照等级" prop="liceClass">
          <el-select class="w-250" v-model="submitForm.liceClass" clearable placeholder="请选择">
            <el-option v-for="item in driverLevels" :key="item.id" :value="item.id" :label="item.name"></el-option>
          </el-select>
        </el-form-item>
      </FormModule>

    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible=false">取 消</el-button>
      <el-button type="primary" @click="submit" :loading="loading">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import { editOtherPers } from '@/apis/infoManage/outsidePersonManage'
import { gianSqComplist } from '@/apis/infoManage/compBaseInfo'
import { persTypes, educs, dutyTypes, politicalStatus, driverLevels, impPosts } from '@/constants/infoManage/personManage'
export default {
  data () {
    return {
      impPosts,
      driverLevels,
      politicalStatus,
      educs,
      dutyTypes,
      persTypes,
      complist: [],
      editData: null,
      loading: false,
      visible: false,
      postType: 1, // 区分重要职位，普通职位
      submitForm: {
        persId: '', // 主键-人员流水号，有则为修改，无则为新增
        persType: '', // 人员类型 1:专业技术人 2:企业管理人 3:工程联系人 4:驾驶员 5:机构行政人
        persName: '', // 姓名
        cardID: '', // 身份证号码
        gend: '男', // 性别 男 女 其他
        birth: '',
        educDegr: '', // 文化程度 小学 初中 高中 大专 本科 硕士 博士
        dutyStat: '', // 职责状态 1:正常 2:休假 3:脱岗 4:离职 5:奖励 6:惩罚
        phone: '', // 手机
        politics: '', // 政治面貌 群众 其他 青年团员 中共党员
        famiAddr: '', // 固定住所地址(详细地址)
        dutyDatTim: '', // 职责日期时间
        sociSecu: false, // 是否交社保
        starLiceDat: '', // 驾驶证开始有效日期
        endLiceDat: '', // 驾驶证结束有效日期
        liceClass: '', // 驾驶证等级 C1,B1,B2,A1,A2
        liceCode: '', // 驾驶证号
        lastCompId: '', // 最新企业流水号（存在则为新增记录，不存在则不增加工作记录）
        lastCompName: '', // 最新企业名称[norm_comp冗余]
        // cityName: '', // 籍贯-市级名称
        // provinName: '', // 籍贯-省份名称
        // regiCityId: '',
        // regiProvinId: '',
        seniPosit: '', // 重要岗位(在职,多个逗号隔开) 1:法人 10:总经理 15:安全副总经理 20:技术负责人 25:车队负责人 30:办公室主任 35:网络管理员 40:统计人员
        ordiPosit: '' // 普通岗位(在职)
      },
      initFormData: null,
      rules: {
        persName: { required: true, message: '请输入姓名' },
        cardID: { pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/, message: '请输入正确的身份证号', trigger: 'change' },
        persType: { required: true, message: '请输入人员类型' },
        phone: { pattern: /^1\d{10}$/, message: '请输入正确的手机号', trigger: 'change' },
        lastCompId: { required: true, message: '请输入任职企业' }
      }
    }
  },
  watch: {
    'submitForm.lastCompId': {
      immediate: true,
      handler (compId) {
        if (compId) {
          const target = this.complist.find(item => item.compId == compId)
          this.submitForm.lastCompName = target?.compName
        }
      }
    }
  },
  created () {
    this.getComps()
  },
  methods: {
    ...mapActions('account', ['refreshToken']),
    compareToForm (submitForm) {
      return XEUtils.isEqualWith(this.initFormData, XEUtils.clone(submitForm, true))
    },
    // 验证字典，比较字段，重置字段，关闭弹窗，刷新列表事件
    submit () {
      const submitForm = { ...this.submitForm }
      console.log(submitForm, 'submitForm')
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.compareToForm(submitForm)) {
            this.$notify.success({ title: '成功', message: '保存成功：无内容变更！' })
            this.visible = false
            return true
          } else {
            this.loading = true
            editOtherPers(submitForm).then((res) => {
              this.loading = false
              if (res.code == 200) {
                this.$notify.success({ title: '成功', message: '保存成功！' })
                this.$emit('refresh')
                this.visible = false
              } else {
                this.$notify.error({ title: '失败', message: res.msg })
              }
            })
          }
        } else {
          return false
        }
      })
    },
    open (editData) {
      this.visible = true
      this.editData = editData

      if (editData) {
        this.postType = editData.seniPosit ? 2 : 1
        Object.keys(this.submitForm).forEach((key) => {
          const value = editData[key]
          if (value !== undefined && value !== null) { this.submitForm[key] = value }
        })
      }

      // 初始表单
      this.initFormData = XEUtils.clone(this.submitForm, true)
    },
    handlerClose () {
      const sourceForm = this.$options.data(this).submitForm
      this.submitForm = Object.assign({}, this.submitForm, sourceForm)
      this.$refs.form.resetFields()
      this.editData = null
      this.postType = 1
    },
    getComps () {
      gianSqComplist({
        pageNum: 1,
        pageSize: 999
      }).then((res) => {
        this.complist = res.data.rows
      })
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .el-dialog__body {
  max-height: calc(95vh - 100px);
  overflow-y: auto;
}
::v-deep .el-dialog {
  top: 50%;
  margin: 0 auto 0!important;
  transform: translateY(-50%);
}
</style>
