<template>
  <PageContainer>
    <BaseSearchForm
      @search="onSearch"
      @reset="onReset"
      :loading="loading"
    >
      <!-- <el-form-item label="企业:">
        <el-select v-model="companySelected" filterable placeholder="全部企业" clearable>
          <el-option v-for="item in companyList" :key="item.compId" :value="item.compId" :label="item.compName"/>
        </el-select>
      </el-form-item>
      <el-form-item :label="compTypes.title+':'">
        <el-select v-model="searchForm.compType" filterable  clearable>
          <el-option v-for="item in compTypes.options" :key="item.value" :value="item.value" :label="item.label"/>
        </el-select>
      </el-form-item> -->
      <el-form-item label="姓名:">
        <el-input v-model.trim="searchForm.persName" placeholder="请输入" clearable/>
      </el-form-item>
      <el-form-item label="身份证:">
        <el-input v-model.trim="searchForm.cardID" placeholder="请输入" clearable />
      </el-form-item>
    </BaseSearchForm>

    <BaseTable
      id="outsidePersonManage"
      @page-change="handlePageChange"
      :tablePage="tablePage"
      :loading="loading"
      :data="tableData">
      <template #buttons>
        <el-button size="small" type="primary" icon="el-icon-plus" @click="handleAdd()">新增</el-button>
      </template>
      <vxe-column field="seq" type="seq" title="序号" width="80" align="center"></vxe-column>
      <vxe-column field="persName" title="员工姓名" min-width="120"></vxe-column>
      <vxe-column field="gend" title="性别" min-width="80"></vxe-column>
      <vxe-column field="politics" title="政治面貌" min-width="100"></vxe-column>
      <vxe-column field="dutyStatName" title="任职状态" min-width="100"></vxe-column>
      <vxe-column field="persTypeName" title="职位/工种" min-width="100"></vxe-column>
      <vxe-column field="lastCompName" title="现工作单位" min-width="220"></vxe-column>
      <vxe-column field="cardID" title="身份证号" min-width="200"></vxe-column>
      <vxe-column field="phone" title="联系方式" min-width="120"></vxe-column>
      <vxe-column field="sociSecuName" title="是否交社保" min-width="120"></vxe-column>
      <vxe-column field="operations" title="操作" width="120" fixed="right">
        <template #default={row}>
          <el-button size="small" type="primary" @click="handleAdd(row)">修改</el-button>
        </template>
      </vxe-column>
    </BaseTable>

    <!-- 新增人员 -->
    <add ref="addRef" :visible.sync="addVisible" :edit-data.sync="editData" @refresh="initList" />
    <!-- 离职 -->
    <!-- <leave-comp :visible.sync="leaveVisible" :edit-data.sync="editData" @refresh="initList" /> -->
    <!-- 详情 -->
    <!-- <detail :type="1" :visible.sync="detailVisible" :edit-data.sync="editData" /> -->
    <!-- 奖惩 -->
    <!-- <bonus-penalty :visible.sync="bonusVisible" :edit-data.sync="editData" @refresh="initList" /> -->
    <!-- 转正 -->
    <!-- <become-full :visible.sync="fullVisible" :edit-data.sync="editData" @refresh="initList" />
    <qr-code :visible.sync="codeVisible" :content="qrCodeContent" /> -->
  </PageContainer>
</template>

<script>
// import { getOtherPersPage, editOtherPers } from '@/apis/infoManage/outsidePersonManage'
import Add from './Add'
import { getOtherPersPage } from '@/apis/infoManage/outsidePersonManage'
import { gianComplist } from '@/apis/common'
// import LeaveComp from './components/LeaveComp'
// import Detail from './components/Detail'
// import BonusPenalty from './components/BonusPenalty'
// import BecomeFull from './components/BecomeFull'
// import QrCode from '@/components/QrCode'
import { dutyTypes, persTypes, impPosts, ccieTypes } from '@/constants/infoManage/personManage'
import { compTypes } from '@/constants/infoManage/compBaseInfo'
export default {
  components: {
    Add
  },
  data () {
    return {
      compTypes,
      dutyTypes,
      persTypes,
      searchForm: {
        persName: '',
        // compType: '', // 企业类型
        cardID: ''
        // lastCompId: ''
      },
      // 列表相关
      loading: false,
      tableData: [],
      tablePage: {
        pageNum: 1,
        pageSize: 20,
        totalSize: 0
      },
      // 新增|编辑相关
      editData: null,
      addVisible: false,
      leaveVisible: false,
      detailVisible: false,
      bonusVisible: false,
      fullVisible: false,
      codeVisible: false,
      qrCodeContent: '',
      companyList: []
    }
  },
  computed: {
    // 选中的企业
    companySelected: {
      set (newVal) {
        const companyList = this.companyList
        const searchForm = this.searchForm
        const { compId } = companyList.find(item => newVal == item.compId) || { compId: '' }
        searchForm.lastCompId = compId
      },
      get () { return this.searchForm.lastCompId }
    }
  },
  created () {
    this.initList()
    this.initCompany()
  },
  methods: {
    handleAdd (rowData) {
      this.$refs.addRef.open(rowData)
    },
    onReset () {
      const source = this.$options.data(this).searchForm
      this.searchForm = Object.assign({}, this.searchForm, source)
      this.onSearch()
    },

    async initCompany () {
      const res = await gianComplist({ pageNum: 1, pageSize: 100000 })
      if (res.code == 200) {
        this.companyList = res.data.rows
      } else {
        this.$message.error(res.msg)
      }
    },
    onSearch () {
      this.tablePage.pageNum = 1
      this.initList()
    },

    initList () {
      this.loading = true
      const { totalSize, ...pageParams } = this.tablePage
      const params = Object.assign({}, this.searchForm, pageParams)
      getOtherPersPage(params).then((res) => {
        this.loading = false
        if (res.code == 200) {
          this.tableData = (res?.data?.rows || []).map((item) => {
            const target = dutyTypes.find(({ id }) => id == item.dutyStat) || { name: '-' }
            const seniPositName = (impPosts.find(({ id }) => id == item.seniPosit) || { name: '' }).name
            const postName = seniPositName || item.ordiPosit || '未知'
            item.persTypeName = postName + '/' + (persTypes.find(({ id }) => id == item.persType) || { name: '' }).name
            item.dutyStatName = target.name
            item.sociSecuName = item.sociSecu ? '是' : (item.sociSecu === null ? '' : '否')
            // 证书类型
            item.ccieTypeName = (ccieTypes.find(({ id }) => id == item.ccieType) || { name: '' }).name
            return item
          })
          this.tablePage.totalSize = res.data.totalSize
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    handlePageChange ({ currentPage, pageSize }) {
      this.tablePage.pageNum = currentPage
      this.tablePage.pageSize = pageSize
      this.initList()
    },

    handlerEdit (row) {
      this.editData = JSON.parse(JSON.stringify(row))
      this.addVisible = true
    },

    // 离职
    handlerLeave (row) {
      this.editData = JSON.parse(JSON.stringify(row))
      this.leaveVisible = true
    },

    // 明细
    handlerDetail (row) {
      this.editData = JSON.parse(JSON.stringify(row))
      this.detailVisible = true
    },

    // 奖惩
    handlerBonus (row) {
      this.editData = JSON.parse(JSON.stringify(row))
      this.bonusVisible = true
    },

    // 奖惩
    handlerFull (row) {
      this.editData = JSON.parse(JSON.stringify(row))
      this.fullVisible = true
    },

    // 删除
    handlerDelete (row) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // const params = { cardID: row.cardID, persId: row.persId }
        // delPers(params).then((res) => {
        //   if (res.code == 200) {
        //     this.initList()
        //     this.$message.success('刪除成功！')
        //   } else {
        //     this.$message.error(res.msg)
        //   }
        // })
      })
    },
    changePersType () {
      this.$confirm('确定要给该人员转正吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // persStatChange()
        // dutyDatTim
        // dutyStat
        // dutyReason
        // const params = { cardID: row.cardID, persId: row.persId }
        // delPers(params).then((res) => {
        //   if(res.code == 200) {
        //     this.initList()
        //     this.$message.success('刪除成功！')
        //   } else {
        //     this.$message.error(res.msg)
        //   }
        // })
      })
    },
    handlerQrcode (row) {
      this.codeVisible = true
      this.qrCodeContent = `姓名: ${row.persName};身份证号: ${row.cardID};`
    }
  }
}
</script>

<style lang="scss" scoped>
  .system-icon-box {
    .system-icon-item {
      object-fit: contain;
      vertical-align: middle;
    }
  }
</style>
