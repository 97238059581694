// 角色类型
export const compTypes = {
  title: '企业类型',
  options: [
    { value: 1, label: '混凝土企业' },
    { value: 2, label: '砂浆企业' },
    { value: 3, label: '水泥企业' },
    { value: 4, label: '沥青企业' },
    { value: 5, label: '砖块企业' },
    { value: 6, label: '水稳企业' },
    { value: 7, label: 'PC构件企业' },
    { value: 8, label: '砂石企业' },
    { value: 9, label: '骨料企业' }
  ]
}
