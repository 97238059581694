import http from '@/utils/http'

// 外部关联人员查询
export function getOtherPersPage (p) {
  return http.post('/unibu/pers/getOtherPersPage', p)
}

// 新增或者修改外部人员
export function editOtherPers (p) {
  return http.post('/unibu/pers/editOtherPers', p)
}
