/**
 * 人员类型
 * id int32
 * name String
 */
export const persTypes = [
  { id: 1, name: '专业技术人' },
  { id: 2, name: '企业管理者' },
  { id: 3, name: '工程联系人' },
  { id: 4, name: '驾驶员' },
  { id: 5, name: '机构行政人' },
  { id: 6, name: '企业职工' }
  // { id: 7, name: '企业驾驶员'},
]

/**
 * 重要岗位
 * id int32
 * name String
 */
export const impPosts = [
  { id: '1', name: '法人' },
  { id: '10', name: '总经理' },
  { id: '15', name: '安全副总经理' },
  { id: '20', name: '技术负责人' },
  { id: '25', name: '车队负责人' },
  { id: '30', name: '办公室主任' },
  { id: '35', name: '网络管理员' },
  { id: '40', name: '统计人员' }
]

/**
 * 正式面貌
 */
export const politicalStatus = [
  { id: '群众', name: '群众' },
  { id: '青年团员', name: '青年团员' },
  { id: '中共党员', name: '中共党员' },
  { id: '其他', name: '其他' }
]

// 任职状态（展示用）
export const dutyTypes = [
  { id: 0, name: '在职(试用)' },
  { id: 1, name: '在职' },
  { id: 2, name: '休假' },
  { id: 3, name: '脱岗' },
  { id: 4, name: '离职' },
  { id: 5, name: '奖励' },
  { id: 6, name: '惩罚' }
]

/**
 * 驾照等级（人员对应列表）类型：
 * id int32
 * name String
 */
export const driverLevels = [
  { id: 'C1', name: 'C1' },
  { id: 'B1', name: 'B1' },
  { id: 'B2', name: 'B2' },
  { id: 'A1', name: 'A1' },
  { id: 'A2', name: 'A2' }
]

/**
 * 驾照等级（人员对应列表）类型：
 * id int32
 * name String
 */
export const educs = [
  { id: '小学', name: '小学' },
  { id: '初中', name: '初中' },
  { id: '高中', name: '高中' },
  { id: '大专', name: '大专' },
  { id: '本科', name: '本科' },
  { id: '硕士', name: '硕士' },
  { id: '博士', name: '博士' }
]

// 是否参保
export const socials = [
  { id: true, name: '是' },
  { id: false, name: '否' }
]

// 证书类型
export const ccieTypes = [
  { id: 0, name: '无证书' },
  { id: 1, name: '培训合格证' },
  // { id: 2, name: '省级'},
  { id: 10, name: '技术上岗证书' }
]
